// Bootstrap
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
@import '../app/variables';


// LAYOUT

$content-bg:                    #f3f3f3; //#eaebee; //#f5f7fa;
$aside-bg:                      #fff;

// NAVBAR TOP

$nav-top-bg:                    $smartilio-blue; //$info;
$nav-top-bg-start:              $nav-top-bg;
$nav-top-bg-end:                darken($nav-top-bg, 5%); //$info-light;
$nav-header-bg:                 transparent;

$nav-top-item:                  #fff;
$nav-top-item-active:           darken($nav-top-bg, 20%);

// SIDEBAR

$sidebar-bg:                      $aside-bg;

$sidebar-item-color:              #515253;
$sidebar-item-color-active:       $nav-top-bg;
$sidebar-item-bg-active:          darken($sidebar-bg, 1%);

$sidebar-icon-color:              inherits;
$sidebar-icon-color-active:       $nav-top-bg;

$sidebar-bullet-color:            rgba(0,0,0,.25);
$sidebar-bullet-color-active:     $sidebar-icon-color-active;

$sidebar-heading-color:           #919DA8;
$sidebar-label-color:             $sidebar-item-color;

// OFFSIDEBAR

$offsidebar-bg:           #fff;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color:        $body-color;

.borderless-td {
    td:not(:last-child) {
        border-right: 0px;
    }
      
    td:not(:first-child) {
        border-left: 0px;
    }
}

.content-bg {
    background-color: $content-bg;
}

@media only screen and (min-width: 1025px) {
    .sidebar:not(.show-scrollbar) {
        margin-right: 0px;
    }
}

/* ========================================================================
   Component: layout
 ========================================================================== */
body, .wrapper .section-container {
    background-color: $content-bg;
}

.wrapper .aside-container {
    background-color: $aside-bg;
}

/* ========================================================================
   Component: top-navbar
 ========================================================================== */

.topnavbar {
    background-color: $nav-top-bg;
    @include gradient-x($nav-top-bg-start, $nav-top-bg-end);

    @include media-breakpoint-up(lg) {
        .navbar-nav > .nav-item.show > .nav-link {
            &, &:hover, &:focus {
                box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
            }
        }
    }

    .navbar-nav > .nav-item > .navbar-text {
        color: $nav-top-item;
    }

    .navbar-nav > .nav-item > .nav-link,
    .navbar-nav > .nav-item.show > .nav-link {
        color: $nav-top-item;
        &:hover, &:focus {
            color: $nav-top-item-active;
        }
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: $nav-top-bg;
    }
}

/* ========================================================================
   Component: sidebar
 ========================================================================== */
.sidebar {
    background-color: $sidebar-bg;

    .nav-heading {
        color: $sidebar-heading-color;
    }
}

// Items
.sidebar-nav {
    > li {
        > a, > .nav-item {
            color: $sidebar-item-color;

            &:focus, &:hover {
                color: $sidebar-item-color-active;
            }

            // Item icon
            > em {
                color: $sidebar-icon-color;
            }

        }

        // Active item state
        &.active, &.open {
            &, > a, > .nav-item, .sidebar-nav {
                background-color: $sidebar-item-bg-active;
                color: $sidebar-item-color-active;
            }

            > .nav-item > em, > a > em {
                color: $sidebar-icon-color-active;
            }

        }

        &.active {
            border-left-color: $sidebar-item-color-active;
        }

    }

}

.sidebar-subnav {
    background-color: $sidebar-bg;

    > .sidebar-subnav-header {
        color: $sidebar-item-color;
    }

    > li {
        > a, > .nav-item {
            color: $sidebar-item-color;
            padding-left: 60px;

            &:focus, &:hover {
                color: $sidebar-item-color-active;
            }

        }

        &.active {
            > a, > .nav-item {
                color: $sidebar-icon-color-active;

                &:after {
                    border-color: $sidebar-bullet-color-active;
                    background-color: $sidebar-bullet-color-active;
                }

            }

        }

    }

}

/* ========================================================================
   Component: offsidebar
 ========================================================================== */
.offsidebar {
    border-left: 1px solid $offsidebar-border-color;
    background-color: $offsidebar-bg;
    color: $offsidebar-color;
}

/* ========================================================================
   Component: button
 ========================================================================== */
.btn-outline-info, .btn-outline-danger {
    background-color: transparent;
    border-width: 1px;
}

/* ========================================================================
   Component: header
 ========================================================================== */
.content-wrapper .content-heading { 
    height: 40px;
    background-color: $smartilio-light-gray;
    color: $smartilio-blue;
}

/* ========================================================================
   Component: footer
 ========================================================================== */
.footer-container { 
    background-color: $smartilio-light-gray;
}

/* ========================================================================
   Component: modal
 ========================================================================== */
 .modal {
     .modal-content {
         .modal-body{
             background-color: $content-bg;
         }
     }
 }

s1-table .table th {
    color: #454545;
    background-color: $smartilio-light-gray;
    padding: 5px 8px;
}

s1-table .table.table-bordered thead th {
    color: #454545;
    border-top: 0px solid $table-border-color;
    padding: 5px 8px;
}

s1-table .table td, s1-table .table-bordered td {
    padding: 6px;
}

/* ========================================================================
   Component: close button
 ========================================================================== */
 button.close {
    font-family: "Arial";
}

/* ========================================================================
   Component: input check
 ========================================================================== */
 .switch input:checked + span {
    background-color: $smartilio-blue;
 }
 .switch input:not(:checked) + span {
    background-color: $smartilio-light-gray;
 }


/* ========================================================================
   Smartilio icons styles
========================================================================== */
.icon-smartilio {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'smartilio' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 20px;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  vertical-align: bottom;
}

.icon-activation {
  &:before {
    content: "\e901"; 
  }
}

.icon-asset {
  &:before {
    content: "\e903"; 
  }
}

.icon-filesystem {
  &:before {
    content: "\e905"; 
  }
}

.icon-group {
  &:before {
    content: "\e907"; 
  }
}

.icon-network {
  &:before {
    content: "\e909"; 
  }
}

.icon-android {
  &:before {
    content: "\e910";
  }
}

.icon-settings {
  &:before {
    content: "\e90b"; 
  }
}

.remote-info {
    cursor: pointer;
    text-decoration: underline;
  }